import React from 'react';

import Hero from './Hero/Hero';
import Services from './Services/Services';
import Work from './Work/Work';
import Community from './Community/Community';
import Updates from './Updates/Updates';
import Contact from './Contact/Contact';

export default ({rawQueryData}) => {
  return (
    <div>
      <Hero />
      <Services services={rawQueryData.allContentfulService.nodes} />
      <Work projects={rawQueryData.allContentfulProject.nodes} />
      <Community community={rawQueryData.contentfulCommunity} />
      <Updates posts={rawQueryData.allContentfulBlogPost.nodes} />
      <Contact />
    </div>);
}