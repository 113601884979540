import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import Img from 'gatsby-image';

const Project = styled(Link)`
  width: 100%;
  text-align: center;
  &:hover {
    text-decoration: none;
  }
  h5 {
    ${props => props.theme.main.fonts.heading}
    margin-bottom: 3px;
  }
  p {
    ${props => props.theme.main.fonts.body}
  }
  ${breakpoint('md')`
    text-align: left;
  `}
`;

const StyledImg = styled(Img)`
  max-width: 535px;
  width: 100%;
  height: auto;
  margin-bottom: 10px;
  
`;


export default ({project}) => {
  return (
    <Project to={`/projects/${project.slug}`}>
      <StyledImg alt={project.heading} fluid={project.projectPageThumbnail.fluid} />
      <h5>{project.heading}</h5>
      <p>{project.subHeading}</p>
    </Project>);
}