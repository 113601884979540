import React from 'react';
import { Container } from 'reactstrap';
import styled from 'styled-components';
import { Link } from 'gatsby';
import breakpoint from 'styled-components-breakpoint';
import Slider from 'react-slick';

import { FaCaretRight } from 'react-icons/fa';

import Technology from './Technology';

const Services = styled.div`
  overflow: hidden;
  h3 {
    ${props => props.theme.main.fonts.displayLarge}
  }
  p {
    ${props => props.theme.main.fonts.body}
  }
  a {
    ${props => props.theme.main.fonts.textLink}
    text-decoration: underline;
  }
`;

const ServiceContainer = styled(Slider)`
  margin-top: 45px;
  .slick-list {
    overflow: visible;
    .slick-slide {
      display: flex;
      justify-content: center;
      a {
        text-decoration: none;
      }
    }
  }
`;

const Service = styled(Link)`
  position: relative;
  margin-bottom: 20px;
  &>div {
    background-color: ${props => props.theme.main.colors.grey.light};
    position: relative;
    width: 240px;
    height: 240px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    transition: all 500ms;

    z-index: 1;
    h5 {
      ${props => props.theme.main.fonts.heading}
      margin-bottom: 3px;
    }
    p {
      ${props => props.theme.main.fonts.body}
      margin-bottom: 0;
    }
    img {
      width: 36px;
    }

    ${breakpoint('xl')`
      width: 300px;
      height: 300px;

      img {
        width: 45px;
      }
    `}
  }
  &:focus {
    outline: none;
  }
  &:hover {
    &>div {
      transform: scale(1.1, 1.1);
      background-color: ${props => props.theme.main.colors.orange.regular};
      h5, p {
        color: white;
      }
      img {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(305deg) brightness(103%) contrast(103%);
      }
    }
    &:before {
      opacity: 1;
      bottom: -12px;
    }
  }
  &:before {
    opacity: 0;
    left: 50%;
    transform: translateX(-50%);
    content: '';
    position: absolute;
    width: 205px;
    height: 145px;
    background-color: ${props => props.theme.main.colors.orange.regular};
    box-shadow: 0 2px 50px 0 rgba(33, 43, 54, 0.48), 0 2px 50px 0 rgba(240, 109, 28, 0.65);
    bottom: 0;
    z-index: -1;
    transition: bottom 500ms, opacity 500ms;
  }
`;

export default ({services}) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };
  return (
    <Services>
      <Container>
        <h3>Strategize, and create</h3>
        <p>We take your requirements and fulfill them with cutting edge engineering feats</p>
        <Link to="/services/">All services <FaCaretRight /></Link>
        {/*<ServiceContainer>*/}
        <ServiceContainer {...settings}>
          {services.filter(service => service.isFeatured).map((service, i) => 
            <Service key={i}  to={`/services#${service.anchor}`}>
              <div>
                <img src={service.icon.file.url} />
                <h5>{service.title}</h5>
                {service.technologyBulletList.slice(0,2).map((tech, i) => <p key={i}>{tech}</p>)}
              </div>
            </Service>       
          )}
        </ServiceContainer>
        {/*</ServiceContainer>*/}
        <Technology />
      </Container>
    </Services>)

}