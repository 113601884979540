import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { Link } from 'gatsby';

import { FaAws } from 'react-icons/fa';
import { FaJava } from 'react-icons/fa';
import { FaNode } from 'react-icons/fa';
import { FaPhp } from 'react-icons/fa';
import { DiPostgresql } from 'react-icons/di';
import { FaReact } from 'react-icons/fa';
import { FaSass } from 'react-icons/fa';
import { DiSwift } from 'react-icons/di';
import { FaVuejs } from 'react-icons/fa';

const TechnologiesContainer = styled.div`
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  margin-top: 64px;

  ${breakpoint('xl')`
    justify-content: space-between;
  `}
`;

const Technology = styled(Link)`
  &>div {
    width: 80px;
    height: 80px;
    margin: 10px;
    background-color: ${props => props.theme.main.colors.grey.light};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 500ms;
    cursor: pointer;
    svg {
      fill: ${props => props.theme.main.colors.grey.darker};
      width: 60px;
      height: 60px;
    }
    &:hover {
      transform: scale(1.1, 1.1);
      background-color: ${props => props.theme.main.colors.grey.dark};
      svg {
        fill: ${props => props.theme.main.colors.grey.light};
      }
    }
  }
  display: flex;
  justify-content: center;
  width: calc(100% / 3);
  ${breakpoint('sm')`
    width: calc(100% / 5);
  `}
  ${breakpoint('md')`
    width: calc(100% / 7);
  `}
  ${breakpoint('lg')`
    width: calc(100% / 9);
  `}
`;

export default () => {
  return (
    <TechnologiesContainer>
      <Technology to="/about#technology"><div><FaAws /></div></Technology>
      <Technology to="/about#technology"><div><FaJava /></div></Technology>
      <Technology to="/about#technology"><div><FaNode /></div></Technology>
      <Technology to="/about#technology"><div><FaPhp /></div></Technology>
      <Technology to="/about#technology"><div><DiPostgresql /></div></Technology>
      <Technology to="/about#technology"><div><FaReact /></div></Technology>
      <Technology to="/about#technology"><div><FaSass /></div></Technology>
      <Technology to="/about#technology"><div><DiSwift /></div></Technology>
      <Technology to="/about#technology"><div><FaVuejs /></div></Technology>
    </TechnologiesContainer>)
}