import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { Container } from 'reactstrap';
import { animateScroll as scroll, scroller } from 'react-scroll'
import { FaCaretDown } from 'react-icons/fa';

import Button from 'components/Common/Button';

import heroImage from 'images/hero.svg';

const Hero = styled.div`
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto auto auto auto;
  grid-template-rows: auto auto auto auto;
  margin-top: 20px;
  ${breakpoint('lg')`
    margin-top: 0;
  `}
`;

const StyledHeroImage = styled.img`
  width: 100%;
  height: auto;

  -ms-grid-row: 3;
  -ms-grid-row-span: 2;
  grid-row: 3 / 5;
  -ms-grid-column: 3;
  -ms-grid-column-span: 3;
  grid-column: 3 / 6;

  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  z-index: 1;

  ${breakpoint('sm')`
    -ms-grid-row: 2;
    grid-row-start: 2;
  `}
  ${breakpoint('md')`
    -ms-grid-row: 1;
    grid-row-start: 1;
  `}
`;

const Heading = styled.div`
  z-index: 1;
  width: 100%;
  h1 {
    ${props => props.theme.main.fonts.displayLarge}
    font-weight: 600;
  }
  h2 {
    ${props => props.theme.main.fonts.displaySmall}
  }

  ${breakpoint('md')`
    h1 {
      ${props => props.theme.main.fonts.displayXLarge}
      font-weight: 600;
    }
    width: 75%;
  `}
  ${breakpoint('lg')`
    width: 50%;
  `}
`;


const StyledContainer = styled(Container)`
  ${props => props.theme.main.fonts.heading}

  -ms-grid-row: 1;
  -ms-grid-row-span: 3;
  grid-row: 1 / 4;
  -ms-grid-column: 1;
  -ms-grid-column-span: 5;
  grid-column: 1 / 6;
  z-index: 2;

  ${breakpoint('md')`
    -ms-grid-row: 1;
    grid-row-start: 1;
    margin-top: 64px;
  `}
`;

export default () => {
  return (
    <div>
    <Hero>
      <StyledContainer>
        <Heading>
          <h1>We are a full service development studio</h1>
          <h2>Mobile Apps, Integrations, Cloud Computing &amp; Enterprise Consulting</h2>
          <Button onClick={() => scroller.scrollTo('projects', { duration: 1000, smooth: true })}>Our Projects <FaCaretDown /></Button>
        </Heading>
      </StyledContainer>
      <StyledHeroImage src={heroImage} />
    </Hero>
    </div>);
}