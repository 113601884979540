import React from 'react';
import styled from 'styled-components';
import { Container } from 'reactstrap';
import breakpoint from 'styled-components-breakpoint';

import Project from './Project';

const Work = styled(Container)`
  margin-top: 64px;
  h3 {
    ${props => props.theme.main.fonts.displayLarge}
    margin-bottom: 0;
  }
  h4 {
    ${props => props.theme.main.fonts.displayLargeG}
  }
`;

const Projects = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: -15px;
  margin-right: -15px;
  ${breakpoint('md')`
    margin-left: 0;
    margin-right: 0;
  `}
  &>a {
    &:first-of-type {
      margin-right: 10px;
      ${breakpoint('md')`
        margin-right: 40px;
      `}
    }
  }
`;


export default ({projects}) => {
  return (
    <Work name="projects">
      <h3>Our Work</h3>
      <h4>Selected Projects</h4>
      <Projects>
        {projects.map((project, i) => <Project project={project} key={i} />)}
      </Projects>
    </Work>);
}